import React, { useState } from 'react'

import { Typography, makeStyles, LinearProgress } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { useMachine } from '@xstate/react'
import cn from 'classnames'
import { createMachine } from 'xstate'

import { ReactComponent as DownloadIcon } from 'assets/img/download-icon.svg'
import Button from 'components/Blocks/CustomButtons/Button'
import Danger from 'components/Blocks/Typography/Danger'
import { SignedUrl } from 'generated/graphql'
import { HttpContentTypeEnum } from 'utils/generatedFrontendConstants'

const createCORSRequest = (method: string, url: string) => {
  let xhr: XMLHttpRequest | null = new XMLHttpRequest()
  if ('withCredentials' in xhr) {
    // Most browsers.
    xhr.open(method, url, true)
  } else {
    // CORS not supported.
    xhr = null
  }
  return xhr
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    textAlign: 'left',
    marginTop: spacing(2),
  },
  input: {
    display: 'none',
  },
  progress: {
    width: '100%',
    margin: '20px auto',
  },
  fileUploadedButton: {
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: 0,
    paddingRight: 0,
    textTransform: 'none',
    display: 'flex',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionButton: {
    width: 146,
    minWidth: 146,
    height: 126,
    minHeight: 126,
    padding: spacing(2),
    margin: spacing(2),
    marginLeft: 0,
    lineHeight: '2rem',
    whiteSpace: 'normal',
    border: `1px solid ${palette.common.navy25}`,
    '& >span': {
      display: 'flex',
      flexDirection: 'column',
      '& >svg': {
        fill: palette.common.navy25,
      },
      '& #check-icon': {
        width: 35,
        height: 35,
        position: 'absolute',
        top: -10,
        right: -10,
        fill: `${palette.common.secondary}`,
        backgroundColor: 'white',
        borderRadius: 18,
      },
    },
  },
  uploadedActionButton: {
    backgroundColor: palette.common.lightGrey,
    color: 'black',
    border: `2px solid ${palette.common.secondary}`,
    '&:hover': {
      backgroundColor: palette.common.lightGrey,
      color: 'black',
      border: `2px solid ${palette.common.secondary}`,
    },
  },
  uploadIcon: {
    width: 65,
    height: 70,
  },
  downloadIcon: {
    width: 50,
    height: 60,
    marginBottom: 10,
  },
  fileName: {
    textAlign: 'left',
  },
  directions: {
    '& >p': {
      paddingTop: spacing(2),
    },
  },
}))
type MachineActionsCancelLoading = { type: 'CANCEL_LOADING'; fileName?: string | null }

type MachineActions =
  | { type: 'UPLOAD' }
  | { type: 'CANCEL' }
  | { type: 'SUCCESS' }
  | MachineActionsCancelLoading
type MachineStates =
  | { value: 'idle'; context: {} }
  | { value: 'loading'; context: {} }
  | { value: 'canceling'; context: {} }
  | { value: 'completed'; context: {} }
const createUploadMachine = (initial: MachineStates['value']) =>
  createMachine<{}, MachineActions, MachineStates>(
    {
      id: 'uploadFile',
      initial,
      context: {},
      states: {
        idle: {
          on: {
            UPLOAD: 'loading',
            SUCCESS: 'completed',
          },
        },
        loading: {
          on: {
            CANCEL: 'canceling',
            SUCCESS: 'completed',
          },
        },
        canceling: {
          entry: 'cancelAction',
          on: {
            CANCEL_LOADING: [
              { target: 'completed', cond: 'fileExists' },
              {
                target: 'idle',
              },
            ],
          },
        },
        completed: {
          on: {
            UPLOAD: 'loading',
            CANCEL: 'canceling',
          },
        },
      },
    },
    {
      guards: {
        fileExists: (context: {}, event: MachineActions) => {
          return Boolean((event as MachineActionsCancelLoading).fileName)
        },
      },
    },
  )

type Props = {
  fileName?: string | null
  templateUrl: string
  showCancelButton?: boolean
  removeUpload?: () => Promise<string | null | undefined>
  generateUploadUrl: (
    uploadFileName: string,
    contentType: string,
  ) => Promise<SignedUrl | null | undefined>
  onFileUploaded: (file: string, path: string) => void
  templateDescription: React.ReactNode
  uploadDescription: React.ReactNode
  templateHeader: string
  uploadHeader: string
  disableUpload?: boolean
  inputName?: string
}

const RosterUpload: React.FC<Props> = ({
  fileName = '',
  templateUrl,
  showCancelButton,
  removeUpload,
  generateUploadUrl,
  onFileUploaded,
  templateDescription,
  uploadDescription,
  templateHeader,
  uploadHeader,
  disableUpload = false,
  inputName,
}) => {
  const classes = useStyles()

  const [errorMessage, setErrorMessage] = useState('')
  const initialState = fileName ? 'completed' : 'idle'

  const handleFileChange = (e: Event) => {
    e.preventDefault()

    const target = e.target as HTMLInputElement
    // See if there is HTMLFieInputElement that would know the files are there already
    const file: File = (target.files as FileList)[0]

    if (!Object.values(HttpContentTypeEnum).includes(file.type as HttpContentTypeEnum)) {
      setErrorMessage('File type is not valid. Please upload a .csv or .xlsx file.')
    }

    send('UPLOAD')
    const reader = new FileReader()
    reader.onloadend = async () => {
      const signedUrl = await generateUploadUrl(file.name, file.type)
      if (!signedUrl) return null
      const { url, path } = signedUrl
      const xhr = createCORSRequest('PUT', url)
      if (xhr) {
        xhr.setRequestHeader('Content-Type', file.type)
        xhr.onload = () => {
          onFileUploaded(file.name, path)
          send('SUCCESS')
        }
        xhr.onerror = () => {
          send('CANCEL')
        }
        xhr.send(file)
      }
    }
    reader.readAsDataURL(file)
  }

  const uploadFile = () => {
    const input = document.createElement('input')
    input.style.cssText = 'display:none'
    input.type = 'file'
    input.name = inputName || 'participantsUpload'
    input.accept = Object.values(HttpContentTypeEnum).join(',')
    input.onchange = handleFileChange
    document.body.appendChild(input)
    input.click()
  }

  const [state, send] = useMachine(createUploadMachine(initialState), {
    actions: {
      cancelAction: async () => {
        if (removeUpload) {
          // If there was a previous valid file still available after a file was removed, use that
          const newFileName = await removeUpload()
          send({ type: 'CANCEL_LOADING', fileName: newFileName })
        }
        send({ type: 'CANCEL_LOADING' })
      },
      uploadAction: uploadFile,
    },
  })

  const prettyFileName = (name: string) => {
    if (name.length < 60) {
      return name
    }
    return `${name.substring(0, 50)}...${name.substring(name.length - 4, name.length)}`
  }

  return (
    <div className={classes.root}>
      <Typography variant="h6">{templateHeader}</Typography>
      <div className={classes.actionButtons}>
        <Button
          id="download-template"
          className={cn(classes.directions, classes.actionButton)}
          color="secondaryNoBackground"
          onClick={() => window.open(templateUrl, '_self')}
        >
          <DownloadIcon className={classes.downloadIcon} />
          Download Template
        </Button>
        {templateDescription}
      </div>
      <Typography variant="h6">{uploadHeader}</Typography>
      <div className={classes.actionButtons}>
        <Button
          disabled={disableUpload}
          id="upload-roster-file"
          color="secondaryNoBackground"
          className={cn(
            classes.fileUploadedButton,
            classes.actionButton,
            state.matches('completed') ? classes.uploadedActionButton : '',
          )}
          onClick={uploadFile}
        >
          <CloudUploadIcon className={classes.uploadIcon} />
          {!state.matches('completed') && 'Upload Your File'}
          {state.matches('completed') && (
            <>
              Uploaded
              <CheckCircleIcon id="check-icon" />
            </>
          )}
        </Button>
        {uploadDescription}
      </div>
      {fileName && (
        <>
          <Typography className={classes.fileName}>
            File Successfully Uploaded: {prettyFileName(fileName)}
          </Typography>
          <Typography color="textSecondary">
            Feel free to upload a new data file at any time.
          </Typography>
          <div className={classes.actionButtons}>
            {state.matches('loading') && <div>Uploading new file...</div>}
            {state.matches('completed') && (
              <Button
                className={classes.fileUploadedButton}
                onClick={uploadFile}
                color="secondaryNoBackground"
              >
                Import New File
              </Button>
            )}
            {state.matches('canceling') && <div>Canceling import...</div>}
            {state.matches('loading') && showCancelButton && (
              <Button color="secondaryNoBackground" onClick={() => send('CANCEL')}>
                Cancel Import
              </Button>
            )}
            {state.matches('completed') && (
              <Button color="secondaryNoBackground" onClick={() => send('CANCEL')}>
                Remove Import
              </Button>
            )}
          </div>
        </>
      )}
      {(state.matches('importLoading') || state.matches('canceling')) && (
        <LinearProgress className={classes.progress} color="secondary" />
      )}
      {errorMessage && <Danger>{errorMessage}</Danger>}
    </div>
  )
}

export default RosterUpload
