import { Canvg } from 'canvg'
import html2canvas from 'html2canvas'
import { svgAsPngUri } from 'save-svg-as-png'

const saveChartToPNG = async (strategy, elementId) => {
  const node = document.getElementById(elementId)
  const canvas = node.querySelector('canvas')
  const svg = node.querySelector('.recharts-wrapper svg')
  let image = null
  if (canvas && ['auto', 'canvas'].includes(strategy)) {
    image = canvas.toDataURL('image/png')
  } else if (svg && ['auto', 'svg'].includes(strategy)) {
    const options = {
      useCors: false,
      Canvg,
      encoderOptions: 1,
    }
    await svgAsPngUri(svg, options, async uri => {
      image = uri
    })
  } else {
    // Fallback / default case
    const wrapper = document.getElementById('documentWrapper')
    const orginalWrapperHeight = wrapper.style.height
    wrapper.style.height = 'auto'
    const options = {}
    await html2canvas(node, options).then(async can => {
      wrapper.style.height = orginalWrapperHeight
      image = can.toDataURL('image/png')
    })
  }
  return image
}
export default saveChartToPNG
