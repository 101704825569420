import { ApolloClient, createHttpLink, from } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { relayStylePagination, offsetLimitPagination } from '@apollo/client/utilities'
import * as Sentry from '@sentry/react'
import DebounceLink from 'apollo-link-debounce'
import { SentryLink } from 'apollo-link-sentry'
import cloneDeep from 'lodash/cloneDeep'

import { GRAPHQL_ENDPOINT } from './config'

import { QResidentResultsGroup } from 'generated/graphql'
import { logout } from 'utils/authService'
import { ERROR_MESSAGE, RESIDENT_RESULTS_GROUP_LABELS } from 'utils/constants'
import { getCurrentTimezoneStandard } from 'utils/dateUtils'
import { GraphQLErrorsEnum } from 'utils/generatedFrontendConstants'

export const addTimezoneHeader = (headers?: { [key: string]: string }) => {
  const newHeaders = cloneDeep(headers) || {}
  newHeaders['user-timezone'] = getCurrentTimezoneStandard()
  return { headers: newHeaders }
}

const authLink = setContext((_, { headers }) => addTimezoneHeader(headers))

const errorLink = onError(result => {
  Sentry.withScope(scope => {
    const { networkError, graphQLErrors, operation } = result
    scope.setTransactionName(operation.operationName)
    scope.setContext('Apollo GraphQL Operation', {
      operationName: operation.operationName,
      variables: operation.variables,
      extensions: operation.extensions,
    })

    if (graphQLErrors) {
      graphQLErrors.forEach(error => {
        const errorCode = error?.extensions?.errorCode
        if (errorCode !== GraphQLErrorsEnum.UNAUTHORIZED_ACCESS) {
          Sentry.captureMessage(error.message, {
            level: 'error',
            fingerprint: ['{{ default }}', '{{ transaction }}', 'ApolloGraphQLError'],
            contexts: {
              'Apollo GraphQL Error': {
                locations: error.locations,
                path: error.path,
                message: error.message,
                extensions: error.extensions,
              },
            },
          })
        }

        if (
          [GraphQLErrorsEnum.UNAUTHORIZED_ACCESS, GraphQLErrorsEnum.FORBIDDEN].includes(errorCode)
        ) {
          logout()
        }
      })
    }

    if (networkError) {
      Sentry.captureMessage(networkError.message, {
        level: 'error',
        fingerprint: ['{{ default }}', '{{ transaction }}', 'ApolloNetworkError'],
        contexts: {
          'Apollo Network Error': {
            error: networkError,
            message: networkError.message,
            extensions: (networkError as any).extensions,
          },
        },
      })

      // Solution from https://github.com/apollographql/apollo-feature-requests/issues/153
      // to parse unhandled errors from the server that return strings instead of JSON.
      try {
        JSON.parse((networkError as { bodyText: string }).bodyText)
      } catch (e) {
        // If not replace parsing error message with real one
        networkError.message = ERROR_MESSAGE
      }
    }
  })
})

const fetcher = (input: RequestInfo, init?: RequestInit) => {
  // https://docs.logrocket.com/reference/graphql-1#apollo-client-with-graphql
  return window.fetch(input, init)
}

const httpLink = createHttpLink({ uri: GRAPHQL_ENDPOINT, fetch: fetcher })
const logoutAfterware = onError(({ networkError }) => {
  if (
    networkError &&
    'statusCode' in networkError &&
    [401, 403].includes(networkError.statusCode)
  ) {
    logout()
  }
})

const cache = new InMemoryCache({
  dataIdFromObject: (object: any) => {
    const theId = object.uuid || object.id
    if (theId) {
      return `${object.__typename}-${theId}`
    }
    return undefined
  },
  typePolicies: {
    StatementScoreType: {
      fields: {
        residentResultsGroup: {
          read: (group?: QResidentResultsGroup) => group && RESIDENT_RESULTS_GROUP_LABELS[group],
        },
      },
    },
    ActionItemTaskNode: {
      fields: {
        dueDate: {
          // Add timezone to Date objects so that browser doesn't assume they are in UTC and convert the time.
          read: (dueDate?: string) => dueDate && `${dueDate} 00:00:00`,
        },
      },
    },
    // We don't want the InsightsSurveyNode to be normalized and used in caching (per dataIdFromObject),
    // but instead we want it to be cached per the query it's being used in. To be specific,
    // we want to incorporate the "filters" query argument in the caching key as it is used to
    // resolve the "response_rate" field.
    // We never expect InsightsSurveyNode to be the result of a mutation since it reflects closed survey scores,
    // and therefore we are fine omitting it from the cache by dataIdFromObject.
    InsightsSurveyNode: {
      keyFields: false,
    },
    Query: {
      fields: {
        users: relayStylePagination([
          'filterValueUuids',
          'search',
          'statuses',
          'sortBy',
          'sortDescending',
        ]),
        actionPlans: relayStylePagination([
          'selectedFollowFilters',
          'filterValueUuids',
          'sortBy',
          'sortAscending',
          'searchQuery',
        ]),
        insightsIndividualResults: offsetLimitPagination([
          'surveyUuid',
          'filters',
          'searchQuery',
          'startDate',
          'endDate',
        ]),
      },
    },
  },
})

// await before instantiating ApolloClient, else queries might run before the cache is persisted
const initClient = async () => {
  return new ApolloClient({
    link: from([
      // For more implementation details checkout this article:
      // https://dev.to/namoscato/graphql-observability-with-sentry-34i6
      new SentryLink({
        uri: `${window.location.origin}/graphql/`,
        // The default values used are not great,
        // see our implementation when capturing the errors for more details.
        setTransaction: false,
        setFingerprint: false,
        attachBreadcrumbs: {
          // Including the query adds a lot of noise, having the operation name
          //  should be enough to know what our query was.
          includeQuery: false,
          includeVariables: true,
          includeFetchResult: false,
          includeError: true,
        },
      }),
      errorLink,
      logoutAfterware,
      authLink,
      new DebounceLink(100),
      httpLink,
    ]),
    cache,
    resolvers: {},
    connectToDevTools: true,
  })
}

export default initClient
