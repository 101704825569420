import React, { useState } from 'react'

import { Dialog } from '@material-ui/core'
import flatten from 'lodash/flatten'
import intersectionBy from 'lodash/intersectionBy'
import uniq from 'lodash/uniq'

import ConfirmGroup from 'components/Settings/Team/Groups/ConfirmGroup'
import { Group } from 'components/Settings/Team/Groups/GroupsTypes'
import FilterAccessSettings from 'components/Settings/Team/Users/FilterAccessSettings'
import UserInfoSettings from 'components/Settings/Team/Users/UserInfoSettings'
import { UserData } from 'components/Settings/Team/Users/Users'
import { GroupNode, SettingsGroupFragment, CurrentUserQuery } from 'generated/graphql'
import { SettingsUser } from 'utils/types'

// Used so we can show one combined set of permissions on the confirm dialog.
const combineGroupPermissions = (groups: Group[]): Group => ({
  name: '',
  users: [],
  accessToSurveyProduct: groups.some(g => g.accessToSurveyProduct),
  canInviteUsers: groups.some(g => g.canInviteUsers),
  canAccessDataStructure: groups.some(g => g.canAccessDataStructure),
  canAccessGeneralSettings: groups.some(g => g.canAccessGeneralSettings),
  canCreateSurveys: groups.some(g => g.canCreateSurveys),
  canManageActionPlans: groups.some(g => g.canManageActionPlans),
  canUseActionPlans: groups.some(g => g.canUseActionPlans),
  canUseAnalytics: groups.some(g => g.canUseAnalytics),
  canAccessSurveySettings: groups.some(g => g.canAccessSurveySettings),
  canManageCertifications: groups.some(g => g.canManageCertifications),
  canViewCertificationResults: groups.some(g => g.canViewCertificationResults),
  allSurveys: groups.some(g => g.allSurveys),
  surveys: flatten(groups.map(g => g.surveys)),
  insightsModules: uniq(flatten(groups.map(g => g.insightsModules))),
  residentCanAccessSurveySettings: groups.some(g => g.residentCanAccessSurveySettings),
  residentCanCreateSurveys: groups.some(g => g.residentCanCreateSurveys),
  residentAccessToSurveyProduct: groups.some(g => g.residentAccessToSurveyProduct),
  canAccessLonelinessResults: groups.some(g => g.canAccessLonelinessResults),
  residentInsightsModules: uniq(flatten(groups.map(g => g.residentInsightsModules))),
})

const getDefaultUser = (validGroups: GroupNode[], userToUpdate?: UserData): SettingsUser => {
  return userToUpdate
    ? {
        firstName: userToUpdate.firstName,
        lastName: userToUpdate.lastName,
        email: userToUpdate.email,
        title: userToUpdate.title,
        id: userToUpdate.id,
        // Populate user groups with the groups from `ValidGroups` query because they contain
        // all the nested properties which we avoid querying on the users table.
        groups: intersectionBy(validGroups, userToUpdate.groups, 'uuid'),
        filterTypesMap: userToUpdate.filterTypesMap,
      }
    : {
        firstName: '',
        lastName: '',
        email: '',
        title: '',
        groups: [],
        filterTypesMap: {},
      }
}

export enum UserDialogSteps {
  USER_INFO = 'userInfo',
  DATA_ACCESS = 'dataAccess',
}

type Props = {
  currentUser: CurrentUserQuery['currentUser']
  validGroups: GroupNode[]
  onUpdateUser(user: SettingsUser): Promise<boolean>
  onClose(): void
  userToUpdate?: UserData
  step?: UserDialogSteps
  loading: boolean
  setStep(step: UserDialogSteps): void
}
/**
 * Dialog which can be opened to
 * - invite a user and take them through multiple steps (user info => filter access)
 * - update a specific step by providing the "step" prop.
 */
const UpdateUser: React.FC<Props> = ({
  currentUser,
  onUpdateUser,
  onClose,
  validGroups,
  userToUpdate,
  step,
  setStep,
  loading,
}) => {
  const [user, setUser] = useState<SettingsUser>(getDefaultUser(validGroups, userToUpdate))
  const [confirmIsOpen, setConfirmIsOpen] = useState(false)
  const submit = async () => {
    const success = await onUpdateUser(user)
    if (success) {
      onClose()
    }
  }
  const goNext = () => {
    if (user.id) {
      submit()
      return
    }
    // Only set filter access for non-admin
    if (step === UserDialogSteps.USER_INFO && !user.groups.some(g => g.isAdmin)) {
      setStep(UserDialogSteps.DATA_ACCESS)
    } else {
      setConfirmIsOpen(true)
    }
  }
  const commonProps = {
    onSubmit: goNext,
    onClose,
    updateUser: (update: Partial<SettingsUser>) => setUser({ ...user, ...update }),
    loading,
  }

  return (
    <>
      {step === UserDialogSteps.USER_INFO && (
        <UserInfoSettings
          {...commonProps}
          submitLabel={user.id ? 'Save' : 'Next'}
          groups={validGroups}
          user={user}
        />
      )}
      {step === UserDialogSteps.DATA_ACCESS && (
        <FilterAccessSettings
          {...commonProps}
          currentUser={currentUser}
          submitLabel={user.id ? 'Save' : 'Send Invitation'}
          selectedFilters={user.filterTypesMap}
          updateFilters={(filterTypesMap: SettingsUser['filterTypesMap']) => {
            setUser({ ...user, filterTypesMap })
          }}
        />
      )}
      <Dialog
        open={confirmIsOpen}
        onClose={() => setConfirmIsOpen(false)}
        aria-labelledby="group-dialog-title"
        aria-describedby="group-dialog-description"
      >
        <div /** Dialogs require a child element */>
          {user.groups.length > 0 && (
            <ConfirmGroup
              confirmType="user"
              title={`
                You are about to invite ${user.firstName} ${user.lastName}
                to groups ${user.groups.map(g => g.name).join(', ')}.
                People ${user.groups.length > 1 ? 'with these groups' : 'in this group'}
                 are able to:
              `}
              // The only situation we show the dialog is when we have assigned user groups
              // directly from the groups array -- so technically the type here is `SettingsGroupFragment`
              // but it's a complicated refactor to separate the group types.
              group={combineGroupPermissions(user.groups as SettingsGroupFragment[])}
              filters={Object.values(user.filterTypesMap)}
              onClose={() => setConfirmIsOpen(false)}
              onConfirm={submit}
            />
          )}
        </div>
      </Dialog>
    </>
  )
}

export default UpdateUser
