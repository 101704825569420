import React from 'react'

import { makeStyles, Dialog, Typography } from '@material-ui/core'

import * as CertifiedImage from 'assets/img/certification_hub/congratulations-certified-image.png'
import * as LocationOnlyCertifiedImage from 'assets/img/certification_hub/location-only-certified-image.png'
import * as NotCertifiedImage from 'assets/img/certification_hub/not-certified-gptw-badge.png'
import Button from 'components/Blocks/CustomButtons/Button'
import { CertificationMessageContentKey } from 'components/CertificationHub/CertificationHubContainer'
import {
  CurrentCertificationQuery,
  CurrentUserQuery,
  useUserUpdateUserAppMessageMutation,
} from 'generated/graphql'
import { CertificationStatusEnum, UserAppMessageEnum } from 'utils/generatedFrontendConstants'

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    paddingTop: spacing(3),
    paddingLeft: spacing(8),
    paddingRight: spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& >p, >h5, >h6': {
      textAlign: 'center',
    },
  },
  fadedOpacityBadge: {
    opacity: 0.3,
  },
}))

type Props = {
  certification: NonNullable<CurrentCertificationQuery['certification']>
  currentUser: CurrentUserQuery['currentUser']
}
const CertificationModalBanner: React.FC<Props> = ({ currentUser, certification }) => {
  const classes = useStyles()
  const [updateAppMesage] = useUserUpdateUserAppMessageMutation()
  let title = 'Congratulations, You’re Great Place to Work-Certified™!'
  let description = "Now it's time to celebrate and tell the world you're #GPTWCertified."
  let detailInfo = `Your scores qualify you to be Great Place to Work-Certified, great job! Now is the time to
  celebrate your Certification. Check out your Certification Hub with all the resources you
  need to get started.`
  let buttonText = 'See Results'
  const imageProps = { src: CertifiedImage, className: '' }
  const numCertifiedLocations = certification.locationCertifications.filter(l => l.isCertified)
    .length
  if (certification.status === CertificationStatusEnum.NOT_CERTIFIED) {
    if (numCertifiedLocations > 0) {
      title = 'Good news - some of your locations are Great Place to Work-Certified!'
      description = `We’re sorry, your organization did not meet the requirements to become certified. But
        ${numCertifiedLocations} of your locations did and are officially Great Place to Work-Certified!`
      detailInfo =
        'For your Certified locations, it’s time to celebrate. Check out your Certification Hub with all the resources you need to get started. For those that didn’t meet the certification requirements, look at your results to learn more about how to certify next time.'
      imageProps.src = LocationOnlyCertifiedImage
    } else {
      title = 'Your scores do not qualify you to be certified as a Great Place to Work™'
      description = "We're sorry."
      detailInfo =
        'Your survey results will help you understand your strengths and areas of opportunity to improve for next year.'
      buttonText = 'See Details'
      imageProps.src = NotCertifiedImage
      imageProps.className = classes.fadedOpacityBadge
    }
  }
  return (
    <Dialog maxWidth="sm" open>
      <div className={classes.container}>
        <Typography variant="h5">{title}</Typography>
        <br />
        <img height={200} {...imageProps} alt="Congratulations Certified." />
        <br />
        <Typography variant="h6">{description}</Typography>
        <br />
        <Typography color="textSecondary">{detailInfo}</Typography>
        <br />
        <br />
        <Button
          onClick={() => {
            const newAppMessage = {
              kind: UserAppMessageEnum.SURVEY_CERTIFICATION,
              contentObjects: [
                { contentType: CertificationMessageContentKey, uuid: certification.uuid },
              ],
            }
            updateAppMesage({
              variables: newAppMessage,
              optimisticResponse: {
                __typename: 'Mutation',
                updateUserAppMessage: {
                  __typename: 'UpdateUserAppMessage',
                  user: {
                    ...currentUser,
                    appMessages: [...currentUser.appMessages, newAppMessage],
                  },
                },
              },
            })
          }}
        >
          {buttonText}
        </Button>
        <br />
        <br />
      </div>
    </Dialog>
  )
}

export default CertificationModalBanner
