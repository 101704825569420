import React, { useContext } from 'react'

import { Typography } from '@material-ui/core'
import orderBy from 'lodash/orderBy'

import useStyles from './styles'

import FormPanel, { EXPAND_ICON } from 'components/Blocks/Accordions/FormPanel'
import ScreenshotButton from 'components/Blocks/CustomButtons/ScreenshotButton'
import CheckboxDropdown from 'components/Blocks/Dropdowns/CheckboxDropdown'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import LessThanMinShowableResults from 'components/Insights/Blocks/LessThanMinShowableResults'
import TimeFrameDropdown, {
  getStartAndEndDateFromTimeframe,
} from 'components/Insights/Blocks/TimeFrameDropdown'
import CustomSurveyComments from 'components/Insights/CustomSurvey/Cards/CustomSurveyComments'
import LinearCard from 'components/Insights/CustomSurvey/Cards/LinearCard'
import MultipleChoiceCard from 'components/Insights/CustomSurvey/Cards/MultipleChoiceCard'
import MultiselectCard from 'components/Insights/CustomSurvey/Cards/MultiselectCard'
import NpsRecommendCard from 'components/Insights/CustomSurvey/Cards/NpsRecommendCard'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsTabProps } from 'components/Insights/InsightsTypes'
import ResponseRateContainer from 'components/Insights/ResponseRate/ResponseRateContainer'
import { gaEvent } from 'config/ga'
import { StoreContext } from 'config/LocalStorage'
import { useInsightsCustomSurveySnapshotQuery, QKind, BenchmarkCodeType } from 'generated/graphql'
import { toggleListWithAllType } from 'utils'
import { useUpdateEffect } from 'utils/customHooks'

const ALL = 'All'

const CustomSurveySnapshotContainer: React.FC<InsightsTabProps> = ({
  survey,
  responseRate,
  filters,
  solution,
}) => {
  const classes = { ...useInsightsStyles(), ...useStyles() }

  const {
    store: { customSurveyQuestionIds, timeframeSettingsBySurvey },
    updateStore,
  } = useContext(StoreContext)

  useUpdateEffect(() => {
    // Reset questions to defaults when insights controls survey changes
    updateStore({
      customSurveyQuestionIds: [ALL],
    })
  }, [updateStore, survey.uuid])
  const timeframeSettings = timeframeSettingsBySurvey[survey.uuid]
  const { startDate, endDate } = getStartAndEndDateFromTimeframe(
    timeframeSettings,
    survey.startDate,
  )
  const result = useInsightsCustomSurveySnapshotQuery({
    variables: {
      startDate,
      endDate,
      filters,
      surveyUuid: survey.uuid,
      skipNpsScore: !survey.includesNpsQuestion,
    },
  })
  return (
    <ResponseHandler {...result}>
      {({ insightsCustomSurvey, insightsNpsGroupScores }) => {
        // Top level `responseRate` from props uses `byParticipant` to check <MIN_SHOWABLE_RESULTS
        // whereas `responseRateSurvey` incorporates whether the rate should use `byClient` for display
        // on the response rate chart.
        const { finished: finishedByParticipant, total: totalByParticipant } = responseRate

        return (
          <>
            <div className={classes.surveyDropdownControlsRow}>
              <TimeFrameDropdown survey={survey} />
              <CheckboxDropdown
                withLeftMargin
                id="questions-selector"
                label="Questions:"
                labelClassName={classes.labelDropdownSelect}
                height={55}
                selectClassName={classes.customDropdownSelect}
                menuItems={[{ value: ALL, isDivider: true }].concat(
                  insightsCustomSurvey.map(({ question }) => ({
                    value: question.uuid,
                    text: question.text,
                    isDivider: false,
                  })),
                )}
                selectedItems={customSurveyQuestionIds}
                onChange={(newSelectedQuestions: string[]) => {
                  if (!newSelectedQuestions.every(Boolean)) return
                  const updatedQuestions = toggleListWithAllType(
                    customSurveyQuestionIds,
                    newSelectedQuestions,
                    ALL,
                    insightsCustomSurvey.length,
                  )
                  gaEvent({
                    action: 'customSurveySelectQuestions',
                    category: 'Insights',
                  })
                  updateStore({
                    customSurveyQuestionIds: updatedQuestions,
                  })
                }}
              />
            </div>
            {finishedByParticipant < survey.minShowableResults ? (
              <LessThanMinShowableResults
                minShowableResults={survey.minShowableResults}
                numParticipants={totalByParticipant}
                productType={survey.productType}
                isUsingFilters={Boolean(filters.length > 0 || startDate || endDate)}
                hasConfidentialResults={survey.hasConfidentialResults}
              />
            ) : (
              <>
                <div className={classes.halfRow}>
                  <div id="survey-name">
                    <Typography variant="h4">{survey.name}</Typography>
                    <Typography color="textSecondary">{survey.description}</Typography>
                  </div>
                  <ResponseRateContainer
                    survey={survey}
                    filterValueUuids={filters}
                    showTitle={false}
                    hasTimeTrending={false}
                  />
                </div>
                {survey.includesNpsQuestion && (
                  <NpsRecommendCard
                    productType={survey.productType}
                    surveyUuid={survey.uuid}
                    filters={filters}
                    npsGroupScores={insightsNpsGroupScores}
                    useFormPanel
                  />
                )}
                {orderBy(insightsCustomSurvey, ({ question }) => {
                  // Order the questions so that NPS follow-up comes first / directly follows the NPS chart above.
                  return question.code !== BenchmarkCodeType.NPS_RECOMMEND_FOLLOWUP
                })
                  .filter(({ question }) =>
                    customSurveyQuestionIds.includes(ALL)
                      ? true
                      : customSurveyQuestionIds.includes(question.uuid),
                  )
                  .map(({ question, answers, selectedFiltersAnswers }, questionIndex) => {
                    const questionId = `question${questionIndex}`
                    const dataIsFiltered = Boolean(selectedFiltersAnswers?.length)
                    const answersToDisplay = dataIsFiltered ? selectedFiltersAnswers : answers
                    const data = answers?.length
                      ? answersToDisplay.map((answer, index) => ({
                          percent: answer?.score ? (answer.score * 100).toFixed(0) : null,
                          name: answer.text,
                          value: answer.count,
                          scale: answer.value,
                          overall: dataIsFiltered
                            ? {
                                percent: ((answers[index].score || 0) * 100).toFixed(0),
                                value: answers[index].count,
                              }
                            : null,
                        }))
                      : []
                    return (
                      <React.Fragment key={question.uuid}>
                        {question.isOpenEnded && (
                          <FormPanel
                            id={questionId}
                            expanded={false}
                            expandIcon={EXPAND_ICON.EXPAND}
                            summary={<Typography>{question.text}</Typography>}
                            loadOnlyExpanded
                          >
                            <div
                              id={`custom-question-${question.uuid}`}
                              className={classes.pageBreak}
                            >
                              <div className={classes.actions}>
                                {/** Using a prefix for the snap ID to help identify analytics */}
                                <ScreenshotButton snapId={`custom-question-${question.uuid}`} />
                              </div>
                              <CustomSurveyComments
                                filters={filters}
                                question={question}
                                survey={survey}
                                enhancedComments={solution.insightsEnhancedComments}
                              />
                            </div>
                          </FormPanel>
                        )}
                        {question.kind === QKind.MULTISELECT && (
                          <MultiselectCard
                            id={questionId}
                            question={question}
                            data={data}
                            dataIsFiltered={dataIsFiltered}
                          />
                        )}
                        {question.kind === QKind.MULTIPLE_CHOICE && (
                          <MultipleChoiceCard
                            id={questionId}
                            question={question}
                            data={data}
                            dataIsFiltered={dataIsFiltered}
                          />
                        )}
                        {question.kind === QKind.LINEAR && (
                          <LinearCard
                            id={questionId}
                            question={question}
                            data={data}
                            dataIsFiltered={dataIsFiltered}
                          />
                        )}
                      </React.Fragment>
                    )
                  })}
              </>
            )}
          </>
        )
      }}
    </ResponseHandler>
  )
}

export default CustomSurveySnapshotContainer
